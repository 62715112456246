/**
 * re-export all the types & queries from contentful-schema.generated.ts
 * and use only those defined here. There should not be any direct use of "contentful-schema.generated.ts" anywhere else
 */

/**
 * We are in process on migration of all types to autogenerated.
 * As soon as we resolve all type we can fix this cycle dep.
 */
// eslint-disable-next-line import/no-cycle
import {
  DateShift,
  FiltersModuleDisplay,
  IExpertsFilterHub,
  FiltersModuleMode,
  Replace,
  RequiredNotNull,
  TypeOfHoroscope,
  ZodiacSignEnum,
  TSocialNetwork,
  ContentfulRichText,
  NonNullableAll,
  ItemViewOptions,
  ListViewOptions,
  IWizardStepTypes,
  IMultiLevelSlugNode,
  IHeadlinePage,
  VerticalAlign,
  TextAlign,
  IOfferBarIcon,
  IExpertSettings,
} from "../types"
import {
  IExpertAdsBannerFragmentContentful,
  ExpertAdsBannerFragmentDocContentful,
  FullDomFragmentDocContentful,
  IFullDomFragmentContentful,
  IAuthModuleFragmentContentful,
  AuthModuleFragmentDocContentful,
  HoroscopeContentFragmentDocContentful,
  IHoroscopeContentFragmentContentful,
  ILinkFieldsFragmentContentful,
  LinkFieldsFragmentDocContentful,
  ExpertProfileFragmentDocContentful,
  IExpertProfileFragmentContentful,
  SeoConfigFragmentDocContentful,
  ISeoConfigFragmentContentful,
  MultiLevelSlugFragmentDocContentful,
  IContentItemFragmentContentful,
  ContentItemFragmentDocContentful,
  NavigationMenuFragmentDocContentful,
  INavigationMenuFragmentContentful,
  LinkBoxFragmentDocContentful,
  ILinkBoxFragmentContentful,
  FooterFragmentDocContentful,
  IFooterFragmentContentful,
  IHeroImageSectionFragmentContentful,
  IImageFormatContentful,
  OfferBarFragmentDocContentful,
  IOfferBarFragmentContentful,
  ConfigFragmentDocContentful,
  IConfigFragmentContentful,
  WizardStepFragmentDocContentful,
  IWizardStepFragmentContentful,
  NavigationBarFragmentDocContentful,
  INavigationBarFragmentContentful,
  IContentItemListFragmentContentful,
  IParagraphFragmentContentful,
  ContentItemListFragmentDocContentful,
  ParagraphFragmentDocContentful,
  IArticleContentHubFragmentContentful,
  ArticleContentHubFragmentDocContentful,
  ArticleFragmentDocContentful,
  IArticleFragmentContentful,
  IMultiLevelSlugFragmentContentful,
  IParentConfigSlugFragmentContentful,
  ParentConfigSlugFragmentDocContentful,
  IPageDataFragmentContentful,
  IWizardFragmentContentful,
  ISideNavigationFragmentContentful,
  ISideNavigationConfigFragmentContentful,
  IExpertProfilePageFragmentContentful,
  SideNavigationConfigFragmentDocContentful,
  IPageSlugWithParentSlugFragmentContentful,
  ISlugWithParentSlugFragmentContentful,
  IAssetFragmentContentful,
  PageForMultilevelSlugFragmentDocContentful,
  IPageForMultilevelSlugFragmentContentful,
  IExpertsListingWithFiltersFragmentContentful,
} from "./contentful-schema.generated"
// eslint-disable-next-line import/no-cycle
import { TAuthModuleDefaultForm } from "../ui-lib/Auth/types"

export type IAuthModule = Readonly<
  Replace<
    RequiredNotNull<IAuthModuleFragmentContentful>,
    "authForm" | "successLoginRedirectPage" | "successRegisterRedirectPage",
    {
      authForm: TAuthModuleDefaultForm
      successLoginRedirectPage: string | null
      successRegisterRedirectPage: string | null
    }
  >
>

export type IExpertAdsBanner = Readonly<IExpertAdsBannerFragmentContentful>

export type IExpertsListingWithFilters = Readonly<
  Replace<
    Required<IExpertsListingWithFiltersFragmentContentful>,
    | "mode"
    | "display"
    | "expertsFilterHub"
    | "freeOnly"
    | "withPagination"
    | "withSearching"
    | "withQueryStringControl",
    {
      mode: FiltersModuleMode
      display: FiltersModuleDisplay[]
      expertsFilterHub: IExpertsFilterHub
      freeOnly: NonNullable<
        IExpertsListingWithFiltersFragmentContentful["freeOnly"]
      >
      withPagination: NonNullable<
        IExpertsListingWithFiltersFragmentContentful["withPagination"]
      >
      withSearching: NonNullable<
        IExpertsListingWithFiltersFragmentContentful["withSearching"]
      >
      withQueryStringControl: NonNullable<
        IExpertsListingWithFiltersFragmentContentful["withQueryStringControl"]
      >
    }
  >
>

export type IFullDom = Readonly<RequiredNotNull<IFullDomFragmentContentful>>

export type IHoroscopeContent = Readonly<
  Replace<
    Required<IHoroscopeContentFragmentContentful>,
    "typeOfHoroscope" | "zodiacSign" | "dateShift",
    {
      typeOfHoroscope: keyof typeof TypeOfHoroscope
      zodiacSign: keyof typeof ZodiacSignEnum
      dateShift: keyof typeof DateShift
    }
  >
>

export type IHeroImageSection = Readonly<
  Replace<
    Required<IHeroImageSectionFragmentContentful>,
    | "imageDesktop"
    | "imageMobile"
    | "contentDesktop"
    | "contentMobile"
    | "verticalAlignDesktop"
    | "verticalAlignMobile"
    | "horizontalAlignDesktop"
    | "horizontalAlignMobile"
    | "colorDesktop"
    | "colorMobile"
    | "horizontalAlignImageDesktop"
    | "horizontalAlignImageMobile"
    | "verticalAlignImageDesktop"
    | "verticalAlignImageMobile"
    | "contentTextShadow",
    {
      imageDesktop: { url: string }
      imageMobile: { url: string } | null
      contentDesktop: ContentfulRichText | null
      contentMobile: ContentfulRichText | null
      verticalAlignDesktop: VerticalAlign
      verticalAlignMobile: VerticalAlign
      horizontalAlignDesktop: TextAlign
      horizontalAlignMobile: TextAlign
      colorDesktop: string
      colorMobile: string
      horizontalAlignImageDesktop: TextAlign
      horizontalAlignImageMobile: TextAlign
      verticalAlignImageDesktop: VerticalAlign
      verticalAlignImageMobile: VerticalAlign
      contentTextShadow: boolean
    }
  >
>

export type IHeroImageSectionCollection = Readonly<{
  items: IHeroImageSection[]
}>

export type ILink = Readonly<
  Replace<
    Required<ILinkFieldsFragmentContentful>,
    "text" | "url" | "socialNetwork",
    {
      url: string
      text: string
      socialNetwork: TSocialNetwork | null
    }
  >
>

export type IExpertProfile = Readonly<
  Replace<
    RequiredNotNull<IExpertProfileFragmentContentful>,
    "config",
    {
      config: IExpertSettings
    }
  >
>

export type ISeoConfig = Readonly<
  Replace<
    Required<ISeoConfigFragmentContentful>,
    "description" | "robots",
    {
      description: string
      robots: string
    }
  >
>

export type IContentItem = Readonly<
  Replace<
    RequiredNotNull<IContentItemFragmentContentful>,
    "image" | "imageDesktop" | "link" | "textAlign",
    {
      image: IAsset
      imageDesktop: IAsset
      link: ILink | IPageForMultilevelSlug
      textAlign?: TextAlign | null
      dataAttributes: Record<string, string> | null
    }
  >
>

export type IContentItemCollection = Readonly<{
  items: IContentItem[]
}>

export type IContentItemList = Readonly<
  Replace<
    Required<IContentItemListFragmentContentful>,
    | "listHeadline"
    | "listSubHeadline"
    | "link"
    | "itemsCollection"
    | "itemViewOptions"
    | "listViewOptions"
    | "dataAttributes",
    {
      listHeadline: string
      listSubHeadline: ContentfulRichText
      link: ILink | IPageForMultilevelSlug | null
      itemsCollection: {
        items: IContentItem[]
      }
      itemViewOptions: ItemViewOptions
      listViewOptions: ListViewOptions
      dataAttributes: Record<string, string> | null
    }
  >
>

export type ILinkBox = Readonly<
  Replace<
    Required<ILinkBoxFragmentContentful>,
    "linksCollection",
    {
      linksCollection: {
        items: (ILink | IPageForMultilevelSlug)[]
      }
    }
  >
>

export type IFooter = Readonly<
  Required<
    Replace<
      IFooterFragmentContentful,
      | "externalLinksCollection"
      | "socialNetworkLinksCollection"
      | "internalLinksCollection",
      {
        internalLinksCollection: {
          items: (ILink | IPageForMultilevelSlug)[]
          __typename: "FooterInternalLinksCollection"
        }
        externalLinksCollection: {
          items: (ILink | IPageForMultilevelSlug)[]
          __typename: "FooterExternalLinksCollection"
        } | null
        socialNetworkLinksCollection: {
          items: ILink[]
          __typename: "FooterSocialNetworkLinksCollection"
        } | null
      }
    >
  >
>

export type INavigationMenu = Readonly<
  RequiredNotNull<
    Replace<
      INavigationMenuFragmentContentful,
      "navigationSubLinksCollection" | "mainLink",
      {
        mainLink: IPageForMultilevelSlug
        navigationSubLinksCollection: {
          items: (INavigationMenu | IPageForMultilevelSlug)[]
        }
      }
    >
  >
>

export type INavigationBar = Readonly<
  Replace<
    RequiredNotNull<INavigationBarFragmentContentful>,
    "navigationItemsCollection",
    {
      navigationItemsCollection: {
        items: INavigationMenu[]
      }
    }
  >
>

export type ISideNavigation = Readonly<
  Replace<
    Required<ISideNavigationFragmentContentful>,
    "blacklistCollection" | "rootSlugsCollection" | "headlinePage",
    {
      blacklistCollection: {
        items: {
          sys: { id: string }
          slug: string
        }[]
      } | null
      rootSlugsCollection: {
        items: Array<IMultiLevelSlugNode>
      } | null
      headlinePage: IHeadlinePage | null
    }
  >
>

export type ISideNavigationConfigCollection = Readonly<{
  items: ISideNavigationConfig[]
}>

export type ISideNavigationConfig = Readonly<
  RequiredNotNull<ISideNavigationConfigFragmentContentful>
>

export type IParagraph = Readonly<
  Replace<
    Required<IParagraphFragmentContentful>,
    "content" | "textAlign",
    {
      content: ContentfulRichText
      textAlign: TextAlign | null
    }
  >
>

export type IArticle = Readonly<
  Replace<
    Required<IArticleFragmentContentful>,
    | "headline"
    | "content"
    | "slug"
    | "parentSlug"
    | "config"
    | "seoStructuredData",
    {
      headline: string
      content: ContentfulRichText
      slug: string
      parentSlug: IParentConfigSlug | null
      config: IConfig | null
      seoStructuredData: Record<string, unknown> | null
    }
  >
>

export type IArticleContentHub = Readonly<
  Replace<
    Required<IArticleContentHubFragmentContentful>,
    "textAlign" | "articleHeadline" | "content" | "seoStructuredData",
    {
      textAlign: TextAlign | null
      articleHeadline: string
      content: ContentfulRichText
      seoStructuredData: Record<string, unknown> | null
    }
  >
>

export type IOfferBar = Readonly<
  Replace<
    NonNullableAll<IOfferBarFragmentContentful>,
    "pageReference" | "offerBarIcon" | "offerBarText",
    {
      pageReference: IPageForMultilevelSlug | null
      offerBarIcon: IOfferBarIcon | null
      offerBarText: string
    }
  >
>

export type IConfig = Readonly<
  Replace<
    IConfigFragmentContentful,
    | "offerBar"
    | "navigationBar"
    | "heroImageCollection"
    | "footer"
    | "sideNavigationCollection"
    | "seoSettings"
    | "filterBox"
    | "introCollection",
    {
      offerBar: IOfferBar | null
      navigationBar: INavigationBar | null
      heroImageCollection: IHeroImageSectionCollection | null
      footer: IFooter | null
      sideNavigationCollection: ISideNavigationConfigCollection | null
      seoSettings: ISeoConfig | null
      filterBox: IExpertsListingWithFilters | null
      introCollection: IContentItemCollection | null
    }
  >
>

export type IWizardStep = Readonly<
  Replace<
    RequiredNotNull<IWizardStepFragmentContentful>,
    "type",
    {
      type: IWizardStepTypes
    }
  >
>

export type IWizard = Readonly<
  Replace<
    RequiredNotNull<IWizardFragmentContentful>,
    | "stepsCollection"
    | "introScreenText"
    | "authScreenAuthModule"
    | "expertScreenTestExpertsListing",
    {
      stepsCollection: {
        items: IWizardStep[]
      }
      introScreenText: IFullDom
      authScreenAuthModule: IAuthModule
      expertScreenTestExpertsListing: IExpertsListingWithFilters
    }
  >
>

export type IMultiLevelSlug = Readonly<
  Replace<
    IMultiLevelSlugFragmentContentful,
    "parentSlug" | "slug",
    {
      parentSlug: IMultiLevelSlug | null
      slug: string
    }
  >
>

export type IParentConfigSlug = Readonly<
  Replace<
    RequiredNotNull<IParentConfigSlugFragmentContentful>,
    "slug" | "config",
    {
      config: IConfig | null
      slug: string
    }
  >
>

interface ISlugWithParent {
  sys: {
    id: string
  }
  slug: string
  parentSlug: {
    sys: {
      id: string
    }
    slug: string
  } | null
}

export type IPageSlugWithParentSlug = Readonly<
  Required<
    Replace<
      IPageSlugWithParentSlugFragmentContentful,
      "slug" | "parentSlug" | "sys",
      ISlugWithParent
    >
  >
>

export type ISlugWithParentSlug = Readonly<
  Required<
    Replace<
      ISlugWithParentSlugFragmentContentful,
      "slug" | "parentSlug" | "sys",
      ISlugWithParent
    >
  >
>

export type IPageForMultilevelSlug = Readonly<
  Required<
    Replace<
      IPageForMultilevelSlugFragmentContentful,
      "parentSlug" | "slug" | "title",
      {
        title: string
        slug: string
        parentSlug: IMultiLevelSlug | null
      }
    >
  >
>

export type IAsset = Readonly<RequiredNotNull<IAssetFragmentContentful>>

export type IPageData = Readonly<
  Replace<
    Required<IPageDataFragmentContentful>,
    "config" | "parentSlug",
    {
      config: IConfig | null
      parentSlug: IParentConfigSlug | null
    }
  >
>

export type IContentHubType =
  | IAuthModule
  | IArticleContentHub
  | IContentItemList
  | IExpertsListingWithFilters
  | IHoroscopeContent
  | IParagraph
  | ILinkBox
  | IFullDom
  | IExpertAdsBanner
  | IWizard

export type IExpertProfilePage = Readonly<
  Replace<
    IExpertProfilePageFragmentContentful,
    "config",
    { config: IConfig | null }
  >
>
export {
  // we temporarily have to give access to fragments
  // so existing queries, which are not converted yet
  // could use already converted ones
  // Please, place such a fragments below
  ExpertAdsBannerFragmentDocContentful,
  FullDomFragmentDocContentful,
  AuthModuleFragmentDocContentful,
  HoroscopeContentFragmentDocContentful,
  ExpertProfileFragmentDocContentful,
  LinkFieldsFragmentDocContentful,
  SeoConfigFragmentDocContentful,
  MultiLevelSlugFragmentDocContentful,
  NavigationMenuFragmentDocContentful,
  LinkBoxFragmentDocContentful,
  PageForMultilevelSlugFragmentDocContentful,
  FooterFragmentDocContentful,
  ArticleContentHubFragmentDocContentful,
  ArticleFragmentDocContentful,
  // we temporarily have to give access to enums
  IImageFormatContentful,
  NavigationBarFragmentDocContentful,
  OfferBarFragmentDocContentful,
  ConfigFragmentDocContentful,
  ContentItemFragmentDocContentful,
  ContentItemListFragmentDocContentful,
  ParagraphFragmentDocContentful,
  WizardStepFragmentDocContentful,
  ParentConfigSlugFragmentDocContentful,
  SideNavigationConfigFragmentDocContentful,
}

import { awsTracking } from "../../utils/tracking"
import { IPublicRuntimeConfig } from "../../utils/runtimeConfig"
import {
  DEFAULT_AUTH_ERROR_MESSAGE,
  TAuthModuleForm,
  AuthModuleForms,
} from "../../ui-lib/Auth/types"
import {
  IJWTProfile,
  TAuthenticationAction,
  ClientTrackingEventName,
  IGetFPData,
} from "../../types"
import { fingerprintsTracking, IDispatchTrackingData } from "../tracking"
import { getMemberType } from "./utils"
import { ISimplifiedApiError } from "../../utils/rest"
import { ReadonlyURLSearchParams } from "next/navigation"

interface ITrackAuth {
  userName: string | undefined
  formType: AuthModuleForms
  authAction: TAuthenticationAction
  trackCustomEvent: (data: Partial<IDispatchTrackingData>) => void
  query: ReadonlyURLSearchParams
  pathname: string
}

interface ITrackSuccessAuth extends ITrackAuth {
  profile: IJWTProfile
  userCreated: boolean
  optin?: boolean
}

type ITrackLogout = Pick<ITrackAuth, "trackCustomEvent">

export const clientTrackingAuth = (
  email: string | undefined,
  error: ISimplifiedApiError | undefined,
  formType: TAuthModuleForm,
  trackEvent: (data: Partial<IDispatchTrackingData>) => void,
  userCreated?: boolean,
  optin?: boolean
): void => {
  const { message = DEFAULT_AUTH_ERROR_MESSAGE, code } = error || {}

  let eventName: ClientTrackingEventName | undefined
  let properties: Record<string, unknown> | undefined

  switch (formType) {
    case AuthModuleForms.LOGIN:
      eventName =
        code !== undefined
          ? ClientTrackingEventName.USER_SINGED_IN_FAILED
          : ClientTrackingEventName.USER_SINGED_IN

      properties =
        code !== undefined
          ? {
              error_code: code,
              error_text: message,
            }
          : {
              on_registration: false,
            }
      break
    case AuthModuleForms.REGISTRATION:
      eventName =
        code !== undefined
          ? ClientTrackingEventName.USER_SINGED_UP_FAILED
          : ClientTrackingEventName.USER_SINGED_IN
      properties =
        code !== undefined
          ? {
              error_code: code,
              error_text: message,
            }
          : {
              on_registration: userCreated,
              optin: !!optin,
            }
      break
    case AuthModuleForms.FORGET_PASSWORD_EMAIL:
      eventName =
        code !== undefined
          ? ClientTrackingEventName.PASSWORD_RECOVERY_FAILED
          : ClientTrackingEventName.PASSWORD_RECOVERY
      properties =
        code !== undefined
          ? {
              email,
              error_text: message,
              error_code: code,
            }
          : {
              email,
            }
      break
    case AuthModuleForms.FORGET_PASSWORD_PASSWORD:
      eventName =
        code !== undefined
          ? ClientTrackingEventName.PASSWORD_UPDATE_FAILED
          : ClientTrackingEventName.PASSWORD_UPDATED

      properties =
        code !== undefined
          ? {
              error_text: message,
              error_code: code,
            }
          : {}
      break
    default:
      break
  }

  if (eventName && properties) {
    trackEvent({
      eventName,
      properties,
    })
  }
}

export async function trackSuccessAuth(
  {
    profile,
    userName,
    formType,
    trackCustomEvent,
    userCreated,
    optin,
  }: ITrackSuccessAuth,
  getRuntimeConfig: () => IPublicRuntimeConfig,
  getFPData: IGetFPData
): Promise<void> {
  const visitorId = await fingerprintsTracking(
    profile,
    getRuntimeConfig,
    getFPData
  )
  const { MANDATOR_NUMBER } = getRuntimeConfig()

  const trackingUserId = profile.user_id
    ? `${MANDATOR_NUMBER}|${profile.user_id}`
    : undefined

  // it's important to call identify before clientTrackingAuth
  // to make users traits available for tracking
  trackCustomEvent({
    method: "identify",
    userId: trackingUserId,
    traits: {
      user_type: getMemberType(profile),
      ...(visitorId && { fingerprint: visitorId }),
    },
  })

  clientTrackingAuth(
    userName,
    undefined,
    formType,
    trackCustomEvent,
    userCreated,
    optin
  )

  if (formType === AuthModuleForms.REGISTRATION) {
    awsTracking.track(getRuntimeConfig, getFPData)
  }
}

export function trackLogout({ trackCustomEvent }: ITrackLogout): void {
  trackCustomEvent({
    eventName: ClientTrackingEventName.USER_SINGED_OUT,
  })

  trackCustomEvent({
    method: "identify",
    traits: {
      user_status: "logged_out",
    },
  })

  trackCustomEvent({
    method: "identify",
    userId: "",
    isLoggedIn: false,
  })
}

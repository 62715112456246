import { storage } from "../../utils/localStorage"
import { LOCAL_STORAGE_KEYS } from "../../types"
import { ReadonlyURLSearchParams } from "next/navigation"

export function clearAuthData(): void {
  storage.cleanUp(LOCAL_STORAGE_KEYS.AUTH_SUCCESS_REDIRECT_URL)
  storage.cleanUp(LOCAL_STORAGE_KEYS.AUTH_FAILED_REDIRECT_URL)
  storage.cleanUp(LOCAL_STORAGE_KEYS.PRODUCT_FLOW_SETTINGS)
  storage.cleanUp(LOCAL_STORAGE_KEYS.IS_QPLUS_SUBSCRIBER)
}

export const cleanUpAfterAuth = (): void => {
  storage.cleanUp(LOCAL_STORAGE_KEYS.AUTH_SUCCESS_REDIRECT_URL)
  storage.cleanUp(LOCAL_STORAGE_KEYS.AUTH_FAILED_REDIRECT_URL)
}
// array of query params key that should be deleted after logout
const QUERY_PARAMS_TO_CLEAR = ["amount", "method", "authResult"]
export function storeAuthSuccessRedirect(
  path: string,
  searchParams: ReadonlyURLSearchParams,
  hash: string
): void {
  const modifiedHash = hash ? `#${hash}` : ""
  let modifiedQuery = ""

  const currentParams = new URLSearchParams(searchParams)
  if (currentParams.size > 0) {
    QUERY_PARAMS_TO_CLEAR.forEach((p) => currentParams.delete(p))
    const stringifiedQuery = currentParams.toString()
    modifiedQuery = stringifiedQuery ? `?${stringifiedQuery}` : ""
  }

  const resultPath = `${path}${modifiedQuery || ""}${modifiedHash || ""}`
  storage.setItem(LOCAL_STORAGE_KEYS.AUTH_SUCCESS_REDIRECT_URL, resultPath)
}

export function storeAuthFailedRedirect(path: string): void {
  storage.setItem(LOCAL_STORAGE_KEYS.AUTH_FAILED_REDIRECT_URL, path)
}

export function storeWizardAuthentication(): void {
  storage.setItem(LOCAL_STORAGE_KEYS.WIZARD_AUTHENTICATION, true)
}

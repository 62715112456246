import React from "react"
import clsx from "clsx"
import { Box, Chip } from "../../_common"
import { IAdvisorLabels } from "../../../types"
import classes from "./Tags.module.scss"

interface ITagsProps {
  tags: IAdvisorLabels
  tagClassName?: string
}

const isPremium = (tagKey: keyof IAdvisorLabels) => tagKey === "is_premium"
const isAstroTV = (tagKey: keyof IAdvisorLabels) => tagKey === "is_astro_tv"
const isNew = (tagKey: keyof IAdvisorLabels) => tagKey === "is_new"

const getTagLabel = (label: keyof IAdvisorLabels): string => {
  if (isNew(label)) return "neu"
  if (isPremium(label)) return "premium"
  if (isAstroTV(label)) return "astroTV"

  return label
}

interface ITagProps {
  onClick?: () => void
  clickable: boolean
  label: keyof IAdvisorLabels
  labelClassName?: string
  rootClassName?: string
}

export const Tag: React.FC<ITagProps> = ({
  label,
  onClick,
  clickable,
  labelClassName,
  rootClassName,
}) => {
  const root = clsx(
    classes.tag,
    {
      [classes.premiumTag]: isPremium(label),
      [classes.astroTVTag]: isAstroTV(label),
      [classes.newTag]: isNew(label),
    },
    rootClassName
  )

  return (
    <Chip
      onClick={() => {
        if (onClick && clickable) onClick()
      }}
      clickable={clickable}
      label={getTagLabel(label)}
      classes={{
        root,
        label: clsx(classes.tagLabel, labelClassName),
        icon: classes.tagIcon,
      }}
    />
  )
}

const Tags: React.FC<ITagsProps> = ({ tags, tagClassName }) => {
  return (
    <ul className={classes.wrapper}>
      {Object.entries(tags)
        .filter(([, enabled]) => enabled)
        .map(([key]) => (
          <Box component="li" key={key}>
            <Tag
              label={key as keyof IAdvisorLabels}
              rootClassName={tagClassName}
              clickable={false}
            />
          </Box>
        ))}
    </ul>
  )
}

export default Tags

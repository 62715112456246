import { Arguments, mutate } from "swr"

const useSWRUtils = () => {
  const revalidateAllKeys = async () => {
    await mutate(() => true)
  }

  const clearCachedValue = async (
    keys: Array<string | ((s: Arguments) => boolean)>
  ) => {
    for (const key of keys) {
      await mutate(key, undefined, { revalidate: false })
    }
  }

  return {
    revalidateAllKeys,
    clearCachedValue,
  }
}

export default useSWRUtils

"use client"

import React, { useCallback, useEffect, useRef } from "react"
import { usePathname, useSearchParams, useRouter } from "next/navigation"

import { clearAllBodyScrollLocks } from "body-scroll-lock"
import useSWRMutation from "swr/mutation"
import { AxiosError } from "axios"
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react"

import {
  AuthAction,
  IAuthenticationProviderProps,
  AuthEndpoints,
  IAuthConfig,
  IAuthTokenResponse,
} from "./types"
import {
  isPageProtected,
  parseJwtProfileFromToken,
  getAuthFormPageSlug,
  shouldRefreshToken,
  cookieSetter,
  createAuthCookies,
} from "./utils"
import { storage } from "../../utils/localStorage"
import {
  LOCAL_STORAGE_KEYS,
  TAuthenticationAction,
  AdviiCustomEvent,
  PageSlug,
  Cookie,
} from "../../types"
import { useTracking, clearCheckoutId } from "../tracking"
import { useRuntimeConfig } from "../runtimeConfig"
import { apiGetAuthConfig, defaultAuthError } from "./api"
import { useAuthenticationReducer } from "./hooks/useAuthenticationReducer"
import AuthenticationContext from "./AuthenticationContext"
import { emitCustomEvent } from "../../utils/events"
import useInterval from "../useInterval"
import { useUser } from "../user"
import {
  storeAuthSuccessRedirect,
  clearAuthData,
  cleanUpAfterAuth,
} from "./storageUtils"
import cachedToken from "../../utils/graphql/cachedToken"
import cookies from "../../utils/cookies"
import {
  trackSuccessAuth,
  trackLogout,
  clientTrackingAuth,
} from "./trackingUtils"

import {
  AuthModuleForms,
  DEFAULT_AUTH_ERROR_MESSAGE,
  IAuthModulePayload,
  ILoginPayload,
  IRegistrationPayload,
  TAuthModuleForm,
} from "../../ui-lib/Auth/types"
import { awsTracking } from "../../utils/tracking"
import {
  fetchWithConfig,
  getCodeAndMessageFromApiError,
  IFetchWithConfigOptions,
  ISimplifiedApiError,
  SWR_KEYS,
} from "../../utils/rest"
import { buildPageEventNameFromPathname } from "../../utils/utils"
import useSWRUtils from "../api/useSWRUtils"

const TOKEN_EXP_CHECK_INTERVAL = 5000

const AuthenticationProvider: React.FC<IAuthenticationProviderProps> = ({
  children,
  protectedPaths,
  token: cookieToken,
}) => {
  const { getRuntimeConfig } = useRuntimeConfig()

  const { push, replace } = useRouter()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { trackEvent: trackCustomEvent, clientTrackingIsReady } = useTracking()
  const { state, dispatch } = useAuthenticationReducer(cookieToken)
  const { isAuthenticated, token, userId } = state

  const {
    fetchInitialUserData,
    resetUserState,
    state: {
      user: { details },
    },
  } = useUser()
  const protectedPage = isPageProtected(protectedPaths, pathname)
  const isTokenRefreshing = useRef<boolean>(false)
  const { revalidateAllKeys, clearCachedValue } = useSWRUtils()
  const { trigger: apiAuthTrigger } = useSWRMutation(
    "/api/user",
    async (_, { arg, headers }: IFetchWithConfigOptions<IAuthConfig>) => {
      const config = await apiGetAuthConfig({ ...arg, headers })
      try {
        return await fetchWithConfig<IAuthTokenResponse>(
          config,
          getRuntimeConfig,
          "advice"
        )
      } catch (e) {
        console.error(e)

        if (e instanceof AxiosError && e.response) {
          return getCodeAndMessageFromApiError(e, DEFAULT_AUTH_ERROR_MESSAGE)
        }

        return defaultAuthError
      }
    }
  )

  const { trigger: apiPasswordTrigger } = useSWRMutation(
    "_",
    async (_, { arg, headers }: IFetchWithConfigOptions) => {
      try {
        return await fetchWithConfig<Response | ISimplifiedApiError>(
          { ...arg, headers },
          getRuntimeConfig,
          "advice"
        )
      } catch (e) {
        console.error(e)

        if (e instanceof AxiosError && e.response) {
          return getCodeAndMessageFromApiError(e, DEFAULT_AUTH_ERROR_MESSAGE)
        }

        return defaultAuthError
      }
    }
  )
  const { getData: getFPData } = useVisitorData(
    { extendedResult: true },
    { immediate: false }
  )

  const authenticate = useCallback(
    async (
      { userName, password, optin }: IAuthModulePayload,
      formType: TAuthModuleForm,
      redirectUrl: string | false | undefined
    ) => {
      let authAction: TAuthenticationAction = "login"
      let path = AuthEndpoints.LOGIN
      let payload: IRegistrationPayload | ILoginPayload = {
        login: userName,
        secret: window.encodeURIComponent(password),
      }
      if (formType === AuthModuleForms.REGISTRATION) {
        authAction = "register"
        path = AuthEndpoints.REGISTER
        payload = {
          email: userName,
          password: window.encodeURIComponent(password),
          opt_in_accepted: optin,
        }
      }

      const response = await apiAuthTrigger({
        payload,
        path,
      })
      if (!response) {
        return
      }

      if ("isError" in response && response.isError) {
        clientTrackingAuth(userName, response, formType, trackCustomEvent)

        return response
      }

      const {
        access_token: token,
        refresh_token: refreshToken,
      } = (response as IAuthTokenResponse).auth_token

      const profile = parseJwtProfileFromToken(token)

      if (profile) {
        await revalidateAllKeys()
        await trackSuccessAuth(
          {
            profile,
            userName,
            formType,
            ...(authAction === "register" && { optin: !!optin }),
            authAction,
            trackCustomEvent,
            pathname,
            query: searchParams,
            userCreated: authAction === "register",
          },
          getRuntimeConfig,
          getFPData
        )

        cleanUpAfterAuth()

        if (profile.profile_id === "user") {
          const userId = profile.user_id?.toString() || ""

          cachedToken.token = token
          fetchInitialUserData(token)
          createAuthCookies(token, refreshToken, getRuntimeConfig)

          dispatch({
            type: AuthAction.TOKEN,
            token,
            refreshToken,
            profile,
            userId,
          })

          if (redirectUrl) {
            await replace(redirectUrl)
          }
        } else if (profile.profile_id === "advisor") {
          window.location.href = getRuntimeConfig().ADVICE_PORTAL_URL
        }
      }
    },
    [
      apiAuthTrigger,
      trackCustomEvent,
      revalidateAllKeys,
      pathname,
      searchParams,
      getRuntimeConfig,
      getFPData,
      fetchInitialUserData,
      dispatch,
      replace,
    ]
  )

  const sendResetPasswordLink = useCallback(
    async (email: string) => {
      const response = await apiPasswordTrigger({
        url: AuthEndpoints.RESET_PASSWORD,
        method: "POST",
        data: {
          email,
        },
      })

      if ("isError" in response && response.isError) {
        clientTrackingAuth(
          email,
          response,
          AuthModuleForms.FORGET_PASSWORD_EMAIL,
          trackCustomEvent
        )

        return response.code
      }

      clientTrackingAuth(
        email,
        undefined,
        AuthModuleForms.FORGET_PASSWORD_EMAIL,
        trackCustomEvent
      )
    },
    [apiPasswordTrigger, trackCustomEvent]
  )

  const updatePassword = useCallback(
    async (newPassword: string, resetToken: string) => {
      const response = await apiPasswordTrigger({
        url: AuthEndpoints.RESET_PASSWORD,
        method: "PUT",
        data: {
          password: window.encodeURIComponent(newPassword),
          token: resetToken,
        },
      })

      if ("isError" in response && response.isError) {
        clientTrackingAuth(
          undefined,
          response,
          AuthModuleForms.FORGET_PASSWORD_PASSWORD,
          trackCustomEvent
        )

        return response.message
      }

      clientTrackingAuth(
        undefined,
        undefined,
        AuthModuleForms.FORGET_PASSWORD_PASSWORD,
        trackCustomEvent
      )
    },
    [apiPasswordTrigger, trackCustomEvent]
  )

  const validateResetPasswordLink = useCallback(
    async (token: string) => {
      const response = await apiPasswordTrigger({
        url: AuthEndpoints.VALIDATE_RESET_PASSWORD_LINK,
        method: "POST",
        data: {
          token,
        },
      })

      if ("isError" in response && response.isError) {
        clientTrackingAuth(
          undefined,
          response,
          AuthModuleForms.FORGET_PASSWORD_EMAIL,
          trackCustomEvent
        )

        return response.code
      }
    },
    [apiPasswordTrigger, trackCustomEvent]
  )

  const goToAuth = useCallback(
    (authAction?: TAuthenticationAction) => {
      storeAuthSuccessRedirect(pathname, searchParams, window.location.hash)

      push(
        `/${
          authAction ? getAuthFormPageSlug(authAction) : PageSlug.LOGIN
        }?${searchParams}`
      )
    },
    [push, searchParams]
  )

  const logout = useCallback(async () => {
    try {
      await apiAuthTrigger({
        path: AuthEndpoints.LOGOUT,
        payload: undefined,
      })
    } catch (err) {
      console.error(err)
    } finally {
      storage.cleanUp(LOCAL_STORAGE_KEYS.PROMOTION_REMINDER_MODAL_DISPLAYED)
      cachedToken.reset()
      clearAuthData()
      cookies.remove(cookieSetter, Cookie.TOKEN, "", getRuntimeConfig)
      cookies.remove(cookieSetter, Cookie.REFRESH_TOKEN, "", getRuntimeConfig)
      clearCheckoutId()
      clearAllBodyScrollLocks()

      // keys that need to be removed
      await clearCachedValue([
        `${SWR_KEYS.PAYMENT_METHODS}-postpayment`,
        `${SWR_KEYS.PAYMENT_METHODS}-prepayment`,
        (key) => {
          return (
            typeof key === "object" &&
            key !== null &&
            "url" in key &&
            key.url === "promotions"
          )
        },
      ])

      await revalidateAllKeys()

      trackLogout({ trackCustomEvent })

      dispatch({ type: AuthAction.RESET_STATE })

      resetUserState()
    }
  }, [
    apiAuthTrigger,
    getRuntimeConfig,
    clearCachedValue,
    revalidateAllKeys,
    trackCustomEvent,
    dispatch,
    resetUserState,
  ])

  useEffect(() => {
    if (protectedPage && !isAuthenticated) {
      /**
       * add last path in pathname to auth method
       * @example
       * authAction = 'login'
       * pathname = /mein-questico/qmail
       * result "login - qmail"
       */
      const pageName = buildPageEventNameFromPathname(pathname)
      trackCustomEvent({
        method: "page",
        eventName: `login - ${pageName}`,
      })
    }
  }, [pathname, protectedPage, trackCustomEvent, isAuthenticated])

  useEffect(() => {
    if (!isAuthenticated && protectedPage) {
      goToAuth()
    }
  }, [isAuthenticated, protectedPage, goToAuth])

  useEffect(() => {
    if (isAuthenticated && userId && token && !details?.email) {
      fetchInitialUserData(token)
    }
  }, [isAuthenticated, fetchInitialUserData, token, userId, details?.email])

  useEffect(() => {
    awsTracking.clean()

    if (!clientTrackingIsReady) return

    trackCustomEvent({
      method: "identify",
      traits: {
        user_status: isAuthenticated ? "logged_in" : "logged_out",
      },
    })
  }, [])

  const tokenExpiredHandler = useCallback(async () => {
    if (shouldRefreshToken(isTokenRefreshing.current, state.refreshToken)) {
      try {
        isTokenRefreshing.current = true
        const authResponse = await apiAuthTrigger({
          payload: {
            refresh_token: state.refreshToken!,
          },
          path: AuthEndpoints.REFRESH,
          method: "PATCH",
          withAuthHeader: false,
        })
        if (authResponse && "auth_token" in authResponse) {
          const tokenData = authResponse.auth_token
          const profile = parseJwtProfileFromToken(tokenData.access_token)
          if (!profile) return
          const userId = profile.user_id?.toString() || "" // need to clarify JWT token field requirement

          cachedToken.token = tokenData.access_token

          createAuthCookies(
            tokenData.access_token,
            tokenData.refresh_token,
            getRuntimeConfig
          )

          dispatch({
            type: AuthAction.TOKEN,
            refreshToken: tokenData.refresh_token,
            token: tokenData.access_token,
            profile,
            userId,
          })

          emitCustomEvent(AdviiCustomEvent.TOKEN_REFRESH_SUCCESS)
          isTokenRefreshing.current = false
        } else {
          throw new Error("Empty response")
        }
      } catch (err) {
        emitCustomEvent(AdviiCustomEvent.TOKEN_REFRESH_FAILURE)
        isTokenRefreshing.current = false
        logout()
      }
    }
  }, [state.refreshToken, getRuntimeConfig, dispatch, logout])

  /**
   * listen for AdviiCustomEvent.TOKEN_EXPIRED event,
   * refresh token and communicate back the results or
   * logout if refresh failed
   */
  useEffect(() => {
    if (typeof window === "undefined") return

    window.addEventListener(AdviiCustomEvent.TOKEN_EXPIRED, tokenExpiredHandler)

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener(
        AdviiCustomEvent.TOKEN_EXPIRED,
        tokenExpiredHandler
      )
    }
  }, [dispatch, tokenExpiredHandler])

  useInterval(
    () => {
      if (!state.profile?.exp) return

      const shouldRefreshToken = state.profile.exp * 1000 - Date.now() <= 60000

      if (shouldRefreshToken) {
        tokenExpiredHandler()
      }
    },
    isAuthenticated ? TOKEN_EXP_CHECK_INTERVAL : null,
    true
  )

  return (
    <AuthenticationContext.Provider
      value={{
        ...state,
        authenticate,
        logout,
        sendResetPasswordLink,
        validateResetPasswordLink,
        updatePassword,
        goToAuth,
      }}
    >
      {protectedPage && !isAuthenticated ? null : children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider

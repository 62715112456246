import React from "react"
import clsx from "clsx"
import { CSSTransition, TransitionGroup } from "react-transition-group"

import { isExpert } from "../../../utils/typeGuards"
import { Box } from "../../_common"
import { IContentItem, StaticListProps } from "./types"
import classes from "./List.module.scss"

interface ListProps
  extends Pick<
    StaticListProps,
    "listClassName" | "listItemClassName" | "content" | "renderItem" | "slots"
  > {
  timeout?: number
}

interface ItemWrapperProps {
  children: React.ReactNode
  listItemClassName?: string
  [key: string]: any
}

const ItemWrapper = ({
  children,
  listItemClassName,
  ...rest
}: ItemWrapperProps) => {
  return (
    <Box
      component="li"
      className={clsx(classes.listItem, listItemClassName)}
      {...rest}
    >
      {children}
    </Box>
  )
}

export const List: React.FC<ListProps> = ({
  listClassName,
  listItemClassName,
  content,
  renderItem,
  slots = [],
}) => {
  const contentWithSlots: Array<IContentItem> = [...content]

  slots?.forEach(({ position, item }) => {
    contentWithSlots.splice(position, 0, item)
  })

  return (
    <Box
      component="ul"
      className={clsx(classes.list, listClassName)}
      data-testid="experts-list"
    >
      {contentWithSlots.map((item, index) => {
        if (isExpert(item)) {
          return (
            <ItemWrapper
              key={item.listing_number}
              listItemClassName={listItemClassName}
            >
              {renderItem(item, index)}
            </ItemWrapper>
          )
        } else {
          return <ItemWrapper key={`slot_${index}`}>{item}</ItemWrapper>
        }
      })}
    </Box>
  )
}

export const ListWithTransition: React.FC<ListProps> = ({
  listClassName,
  listItemClassName,
  content,
  renderItem,
  timeout = 500, // has no effect for now
}) => {
  return (
    <Box
      component="ul"
      className={clsx(classes.list, listClassName)}
      data-testid="experts-list-with-transition"
    >
      {/* @ts-ignore */}
      <TransitionGroup component={null} appear={false} enter={false}>
        {content.map((item, index) => (
          //@ts-ignore
          <CSSTransition
            key={item.listing_number}
            timeout={timeout}
            classNames={{
              exit: classes.exit,
              exitActive: classes.exitActive,
            }}
          >
            <ItemWrapper listItemClassName={listItemClassName}>
              {renderItem(item, index)}
            </ItemWrapper>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </Box>
  )
}

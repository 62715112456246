import React from "react"
import clsx from "clsx"

import { Box, Typography } from "../../_common"
import Tags from "../Tags"
import { StarRating } from "../Rating"

import {
  IAdvisorLabels,
  IAdvisor,
  IExpertZodiacSign,
  ZodiacSignAliasesEnum,
} from "../../../types"
import LanguageIcon from "../../_common/QuesticoIcon/LanguageIcon"
import { ZodiacSignIcon } from "../../_common/QuesticoIcon"
import classes from "./ExpertShortStats.module.scss"

interface IStatWithIconProps {
  text: string
  icon: ZodiacSignAliasesEnum | "language"
  widthXs?: number
  widthMd?: number
}

const StatWithIcon: React.FC<IStatWithIconProps> = (props) => {
  const { text, icon } = props

  if (!icon) {
    return null
  }

  return (
    <Typography
      component="span"
      color="textPrimary"
      className={classes.iconWrapper}
    >
      {icon === "language" ? (
        <LanguageIcon className={classes.icon} />
      ) : (
        <ZodiacSignIcon variant={icon} className={classes.icon} />
      )}
      {text}
    </Typography>
  )
}

type TExpertShortStatsProps = Pick<IAdvisor, "labels" | "rating"> & {
  sign: IExpertZodiacSign | null
  readingsCount: IAdvisor["readings_count"]
  languages: IAdvisor["languages"] | undefined
}

const hasLabels = (labels: IAdvisorLabels) =>
  Object.values(labels).some((val) => val)

const ExpertShortStats: React.FC<TExpertShortStatsProps> = ({
  labels,
  rating,
  readingsCount,
  sign = null,
  languages = undefined,
}) => {
  const iconSize = {
    xs: 20,
    md: 24,
  }

  const withLanguages = languages && languages.length !== 0

  return (
    <Box component="ul" className={classes.wrapper}>
      {hasLabels(labels) && (
        <Box component="li" className={classes.statsItem}>
          <Tags tags={labels} />
        </Box>
      )}
      <Box component="li" className={classes.statsItem}>
        <StarRating rating={rating} />
      </Box>

      <Box
        component="li"
        className={clsx(classes.statsItem, {
          [classes.withSignAndLang]: sign && withLanguages,
        })}
      >
        <Typography component="span" color="textSecondary">
          {`${readingsCount} ${readingsCount === 1 ? "Gespräch" : "Gespräche"}`}
        </Typography>
      </Box>

      {sign && (
        <Box component="li" className={classes.statsItem}>
          <StatWithIcon
            icon={sign.alias}
            text={sign.name}
            widthXs={iconSize.xs}
            widthMd={iconSize.md}
          />
        </Box>
      )}

      {withLanguages && (
        <Box component="li" className={classes.statsItem}>
          <StatWithIcon
            icon="language"
            text={languages.map((l) => l.name).join(", ")}
            widthXs={iconSize.xs}
            widthMd={iconSize.md}
          />
        </Box>
      )}
    </Box>
  )
}

export default ExpertShortStats

import {
  TPriceFilter,
  ContentfulNode,
  IAdvisorStatus,
  IAdvisor,
} from "../../types"
import { ProductType, Language, Tag, IOrderByType } from "./Filters/types"

export interface IAdvisorIdOnlineStatus {
  id: number
  status: IAdvisorStatus
  avatar: string | undefined
}

export type TAdvisorOnlineStatusMapping = Record<number, IAdvisorIdOnlineStatus>
export enum ExpertOnlineStatusEnum {
  AVAILABLE = "Verfügbar",
  BUSY = "Beschäftigt",
  NOT_AVAILABLE = "Abwesend",
}

export interface IAdvisorOnlineStatusFetchResult {
  list: IAdvisorIdOnlineStatus[]
  count: number
  left: number
}

export interface IExpertsIndexesInView {
  min: number
  max: number
}

/** @deprecated */
export type IExpertListingFilter = {
  categoryNos?: number[]
  productType?: ProductType | "ANONYMOUS" // ALL by default
  priceFilter?: TPriceFilter
  search?: string
  freeOnly?: boolean // false by default
  gender?: string
  language?: Language
  tag?: Tag
}

export interface CategoryType {
  title: string
}

export interface ListingsConfigurationProps
  extends Pick<
      IExpertListingFilter,
      "categoryNos" | "freeOnly" | "productType" | "gender" | "tag"
    >,
    Partial<ContentfulNode> {
  withPagination: boolean // false by default
  size: number
  categories?: Array<CategoryType> // undefined by default
  headline?: string // undefined by default
  minPrice?: number
  maxPrice?: number
  includeListingNos?: number[]
  orderBy?: IOrderByType
  language?: string
}

/**
 * We need stable and unique udentifiers to distinguish between default and alternative listings (potentially some others).
 * We can't use "listingName", because it is created for tracking and subject to change.
 */
export type IListingIdentificator = "alternative" | "default"

export interface IExpertListingProps extends ListingsConfigurationProps {
  searchTerm?: string
  contentfulId?: string
  listingName?: string
  placeholderSize?: number
  emptyState?: React.ReactNode
  errorState?: React.ReactNode
  /**
   * Some components (ExpertEntry, EmptyState...) has different view
   * on mobile and desktop. This props helps to detect
   * whether mobile or desktop view should be applied.
   */
  isExtended?: boolean
  renderExpertItem?: (expert: IAdvisor) => JSX.Element
  /**
   * `'default'` by default
   */
  listingId?: IListingIdentificator
}

export interface IListingSlot {
  position: number
  item: React.ReactNode
}

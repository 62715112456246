import React from "react"
import IconBase from "./IconBase"
import { IIconProps } from "./types"
import { useCheckBrand } from "./utils/useCheckBrand"

const LanguageIcon: React.FC<IIconProps> = ({ color = "currentColor" }) => {
  const { isQuesticoBrand } = useCheckBrand()
  return (
    <IconBase>
      {isQuesticoBrand ? (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.03084 19.2924L8.38642 18.632L8.15738 18.5087L7.90116 18.5537L8.03084 19.2924ZM4.70777 15.9693L5.44647 16.099L5.49395 15.8286L5.3556 15.5914L4.70777 15.9693ZM4 20L3.2613 19.8703L3.07634 20.9236L4.12968 20.7387L4 20ZM7.67527 19.9527C8.97894 20.6547 10.4851 21.0578 12.0001 21.0578V19.5578C10.7458 19.5578 9.48275 19.2224 8.38642 18.632L7.67527 19.9527ZM12.0001 21.0578C17.0297 21.0578 21.0578 17.0297 21.0578 12.0001H19.5578C19.5578 16.2012 16.2012 19.5578 12.0001 19.5578V21.0578ZM21.0578 12.0001C21.0578 6.97048 17.0297 2.94238 12.0001 2.94238V4.44238C16.2012 4.44238 19.5578 7.7989 19.5578 12.0001H21.0578ZM12.0001 2.94238C6.97048 2.94238 2.94238 6.97048 2.94238 12.0001H4.44238C4.44238 7.7989 7.7989 4.44238 12.0001 4.44238V2.94238ZM2.94238 12.0001C2.94238 13.5982 3.34126 15.1152 4.05993 16.3472L5.3556 15.5914C4.78196 14.608 4.44238 13.3558 4.44238 12.0001H2.94238ZM7.90116 18.5537L3.87032 19.2613L4.12968 20.7387L8.16052 20.0311L7.90116 18.5537ZM4.7387 20.1297L5.44647 16.099L3.96907 15.8396L3.2613 19.8703L4.7387 20.1297Z"
            fill={color}
          />
          <path
            d="M4.43994 14.7693H19.5138"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.43994 9.44312H19.5138"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.0585 4.16309C10.3662 5.64924 9.30469 8.44616 9.30469 12.1108C9.30469 13.7446 9.51699 16.7354 11.0585 20.0585"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.8955 4.16309C13.5878 5.64924 14.6494 8.44616 14.6494 12.1108C14.6494 13.7446 14.437 16.7354 12.8955 20.0585"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_19305_240274)">
            <path
              d="M11.9998 21.2308C17.0978 21.2308 21.2306 17.0981 21.2306 12.0001C21.2306 6.90204 17.0978 2.76929 11.9998 2.76929C6.9018 2.76929 2.76904 6.90204 2.76904 12.0001C2.76904 17.0981 6.9018 21.2308 11.9998 21.2308Z"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.1028 2.96411C10.5438 3.75385 10.831 4.55385 10.6977 5.29232C9.83614 10.0821 5.9387 10.1744 3.02588 9.83591"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M14.3489 20.9333C12.3592 16.8821 17.8771 15.3641 17.0361 13.6821C15.9797 11.559 10.3181 12.841 12.8412 8.64616C13.4976 7.54873 15.4566 5.32309 16.5233 3.95898"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3.26172 14.9743C4.80018 14.6051 6.37967 13.2512 8.49249 15.3025C10.7899 17.5384 9.53864 19.3435 9.20018 20.7999"
              stroke={color}
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_19305_240274">
              <rect
                width="20"
                height="20"
                fill="white"
                transform="translate(2 2)"
              />
            </clipPath>
          </defs>
        </svg>
      )}
    </IconBase>
  )
}

export default LanguageIcon

import { TMessageType, IAdvisorProductType } from "../../types"
import { strings } from "./strings"

export interface IAuthModuleBodyProps
  extends Omit<IAuthModuleProps, "authForm"> {
  activeForm: TAuthModuleForm
  redirectToAuthForm: (
    redirectTo: string | { query: URLSearchParams; pathname: string }
  ) => void
  expertEntry: IExpertEntry | null
}

export interface IExpertEntry {
  photo: string
  displayName: string
  price: number
  priceSale: number | null
  productType: IAdvisorProductType
}

export type Language = {
  url: string
  label: string
}

export type TInput = "text" | "password" | "email"

export type TIndicator = "short" | "weak" | "normal" | "strong"

export type TInputError =
  | "isRequired"
  | "invalidPhoneOrEmail"
  | "invalidEmail"
  | "shortPassword"

export type TInputValidation = TInputError | null

export interface Locale {
  current: string | undefined
  supported: Array<Language>
}

export interface IMessage {
  summary: React.ReactNode
  type: TMessageType
}
export interface TrustSection {
  trustSectionTitle: string
  trustSectionSub1: string
  trustSectionSub2: string
}

export const DEFAULT_AUTH_ERROR_MESSAGE = strings.AUTH_ERROR_MESSAGE

export type TLoginMethod = "email" | "username" | "phonenumber"

export type TInputName =
  | "username"
  | "email"
  | "password"
  | "promocode"
  | "password-new"
  | "password-confirm"

export interface IAuthInput extends HTMLInputElement {
  name: TInputName
}

export interface IInput {
  value: string
  required: boolean
}

export type TUseFormValues = {
  [P in TInputName]?: IInput
}

export type TUseFormErrors = {
  [P in TInputName]?: TInputValidation
}

export interface IAuthModuleProps {
  loginTitle: string
  registrationTitle: string
  registrationFooterText: string
  authForm: TAuthModuleDefaultForm
  successLoginRedirectPage: string
  successRegisterRedirectPage: string
  loginFormTitle: string
  loginFormPlaceholder: string
  standalone: boolean
}

export enum AuthModuleForms {
  LOGIN = "Login",
  REGISTRATION = "Registration",
  FORGET_PASSWORD_EMAIL = "ForgetPassword-email",
  FORGET_PASSWORD_PASSWORD = "ForgetPassword-password",
}

export type TAuthModuleForm =
  | AuthModuleForms.LOGIN
  | AuthModuleForms.REGISTRATION
  | AuthModuleForms.FORGET_PASSWORD_EMAIL
  | AuthModuleForms.FORGET_PASSWORD_PASSWORD

export type TAuthModuleDefaultForm = Exclude<
  TAuthModuleForm,
  | AuthModuleForms.FORGET_PASSWORD_EMAIL
  | AuthModuleForms.FORGET_PASSWORD_PASSWORD
>

export interface IRegistrationPayload {
  email: string
  password: string
  opt_in_accepted?: boolean
}

export interface ILoginPayload {
  login: string
  secret: string
}

export interface IAuthModulePayload {
  userName: string
  password: string
  optin?: boolean
}

import React, { memo } from "react"
import { InView } from "react-intersection-observer"

import ExpertEntry from "./ExpertEntry"

interface IExpertEntryContainerProps
  extends React.ComponentProps<typeof ExpertEntry> {
  onViewChange?: (inView: boolean) => void
}

const ExpertEntryContainer: React.FC<IExpertEntryContainerProps> = memo(
  ({ onViewChange, linkClassName = undefined, expert, listingName }) => {
    return (
      <>
        {onViewChange ? (
          //@ts-ignore
          <InView onChange={(inView) => onViewChange(inView)}>
            <ExpertEntry
              expert={expert}
              linkClassName={linkClassName}
              listingName={listingName}
            />
          </InView>
        ) : (
          <ExpertEntry
            expert={expert}
            linkClassName={linkClassName}
            listingName={listingName}
          />
        )}
      </>
    )
  }
)

export default ExpertEntryContainer

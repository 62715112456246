import { Dispatch, useReducer } from "react"

import { IAuthenticationState, TAuthAction } from "../types"
import {
  authenticationReducer,
  initAuthContextState,
} from "../reducers/authenticationReducer"
import { getAuthStateOnServer, getAuthStateOnClient } from "../utils"
import { isServer } from "../../../utils/utils"

interface IAuthenticationReducer {
  state: IAuthenticationState
  dispatch: Dispatch<TAuthAction>
}

function init(token?: string): IAuthenticationState {
  try {
    if (isServer()) {
      return getAuthStateOnServer(initAuthContextState, token)
    }

    return getAuthStateOnClient(initAuthContextState)
  } catch (error) {
    console.error(error)

    return initAuthContextState
  }
}

export const useAuthenticationReducer = (
  token?: string
): IAuthenticationReducer => {
  const [state, dispatch] = useReducer(authenticationReducer, token, init)

  return { state, dispatch }
}

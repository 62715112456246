import React from "react"
import clsx from "clsx"

import { IAdvisorStatus } from "../../../types"
import { Box } from "../../_common"
import { ExpertOnlineStatusEnum } from "../types"
import classes from "./ExpertOnlineStatusLabel.module.scss"

interface IExpertOnlineStatusProps {
  onlineStatus?: IAdvisorStatus
}

const expertAvailabilityStatus: {
  [K in IAdvisorStatus]: ExpertOnlineStatusEnum
} = {
  online: ExpertOnlineStatusEnum.AVAILABLE,
  busy: ExpertOnlineStatusEnum.BUSY,
  offline: ExpertOnlineStatusEnum.NOT_AVAILABLE,
}

const ExpertOnlineStatusLabel: React.FC<IExpertOnlineStatusProps> = ({
  onlineStatus,
}) => {
  if (!onlineStatus) {
    return null
  }

  const rootClassName = clsx(classes.root, {
    [classes.available]: onlineStatus === "online",
    [classes.notAvailable]: onlineStatus === "offline",
    [classes.busy]: onlineStatus === "busy",
  })

  return (
    <Box className={rootClassName}>
      {expertAvailabilityStatus[onlineStatus]}
    </Box>
  )
}

export default ExpertOnlineStatusLabel

import { AuthAction, IAuthenticationState, TAuthAction } from "../types"

export const initAuthContextState: IAuthenticationState = {
  userId: undefined,
  token: undefined,
  refreshToken: undefined,
  isAuthenticated: false,
  profile: null,
}

export function authenticationReducer(
  state: IAuthenticationState,
  action: TAuthAction
): IAuthenticationState {
  switch (action.type) {
    case AuthAction.TOKEN: {
      const { token, refreshToken, profile, userId } = action

      return {
        ...state,
        token,
        refreshToken,
        profile,
        isAuthenticated: true,
        userId,
      }
    }
    case AuthAction.RESET_STATE:
      return initAuthContextState
    default:
      throw new Error("Wrong auth action type")
  }
}

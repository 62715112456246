import useSWRMutation from "swr/mutation"

import useInterval from "../../../../hooks/useInterval"
import { useRuntimeConfig } from "../../../../hooks/runtimeConfig"
import {
  IAdvisorOnlineStatusFetchResult,
  IExpertsIndexesInView,
  TAdvisorOnlineStatusMapping,
} from "../../types"
import { getPollingListingIds } from "../../utils"
import { getIntervalTimeForPolling } from "./utils"
import {
  fetchWithConfig,
  IFetchWithConfigOptions,
} from "../../../../utils/rest"
import { IAdvisorListStatusFilter } from "./types"
import { useEffect } from "react"

const useExpertOnlineStatus = (
  allExpertIds: number[],
  listingContainerInView: boolean,
  { min, max }: IExpertsIndexesInView,
  interval?: number,
  key?: string
): TAdvisorOnlineStatusMapping | undefined => {
  const { getRuntimeConfig, getRuntimeConfigByKey } = useRuntimeConfig()
  const listingIds = getPollingListingIds(allExpertIds, { min, max })

  const shouldFetch = Boolean(listingIds.length && listingContainerInView)

  const { data, trigger, isMutating } = useSWRMutation(
    key || "advisor/list/statuses",
    (
      _,
      { arg: data, headers }: IFetchWithConfigOptions<IAdvisorListStatusFilter>
    ) => {
      return fetchWithConfig<IAdvisorOnlineStatusFetchResult>(
        {
          url: "advisor/list/statuses",
          method: "POST",
          data,
          headers,
        },
        getRuntimeConfig
      )
    }
  )

  /**
   * useInterval will return us the result after the interval time.
   * But in some cases we need this result immediately.
   */
  useEffect(() => {
    if (shouldFetch && listingIds.length > 0) {
      trigger({
        ids: listingIds,
        count: listingIds.length,
      })
    }
  }, [shouldFetch])

  const intervalTime = getIntervalTimeForPolling(
    shouldFetch,
    interval || getRuntimeConfigByKey("EXPERT_ONLINE_STATUS_INTERVAL_MS"),
    isMutating
  )

  useInterval(() => {
    if (!shouldFetch || !intervalTime) {
      return
    }

    trigger({
      ids: listingIds,
      count: listingIds.length,
    })
  }, intervalTime)

  return data?.list?.reduce((acc: TAdvisorOnlineStatusMapping, st) => {
    acc[st.id] = st

    return acc
  }, {})
}

export default useExpertOnlineStatus

import { AdviiCustomEvent } from "../../types"

/** @see https://developer.mozilla.org/en-US/docs/Web/Events/Creating_and_triggering_events#the_old-fashioned_way */
export const emitCustomEvent = (eventName: AdviiCustomEvent) => {
  if (typeof window === "undefined") return

  const event = window.document.createEvent("Event")

  event.initEvent(eventName, true, true)

  window.dispatchEvent(event)
}

import React from "react"
import { Box, Skeleton } from "../../_common"
import classes from "./ExpertItemPlaceholder.module.scss"

const ExpertItemPlaceholder: React.FC = () => {
  return (
    <Box className={classes.wrapper} data-testid="expert-item-placeholder">
      <Box className={classes.avatarWrapper}>
        <Skeleton className={classes.avatarSkeleton} variant="rectangular" />
      </Box>
      <Box className={classes.detailsCol}>
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
      </Box>
    </Box>
  )
}

export default ExpertItemPlaceholder

import { createContext } from "react"

import { TAuthenticationAction } from "../../types"
import { IAuthenticationState } from "./types"
import { initAuthContextState } from "./reducers/authenticationReducer"
import { IAuthModulePayload, TAuthModuleForm } from "../../ui-lib/Auth/types"
import { ISimplifiedApiError } from "../../utils/rest"

interface IAuthenticationContext extends IAuthenticationState {
  authenticate: (
    { userName, password, optin }: IAuthModulePayload,
    authType: TAuthModuleForm,
    redirectUrl: string | false | undefined
  ) => Promise<ISimplifiedApiError | void>
  sendResetPasswordLink: (email: string) => Promise<number | undefined>
  validateResetPasswordLink: (token: string) => Promise<number | void>
  updatePassword: (
    newPassword: string,
    resetToken: string
  ) => Promise<string | void>
  logout: () => void
  goToAuth: (authAction?: TAuthenticationAction) => void
}

export default createContext<IAuthenticationContext>({
  ...initAuthContextState,
  authenticate: () => Promise.resolve(),
  sendResetPasswordLink: () => Promise.resolve(undefined),
  validateResetPasswordLink: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  goToAuth: () => ({}),
})

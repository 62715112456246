import React, { memo, useCallback } from "react"

import clsx from "clsx"
import { IAdvisor } from "../../../types"
import { Typography, Box, AdviqoLink } from "../../_common"
import { buildProfileUrl } from "../../../utils/slugUtils"
import ArrowIcon from "../../_common/QuesticoIcon/ArrowIcon"
import ExpertShortStats from "../ExpertShortStats"
import { Products } from "../Products"
import Avatar from "../Avatar"
import ExpertOnlineStatusLabel from "../ExpertOnlineStatusLabel"
import { useTracking } from "../../../hooks/tracking"
import { trackProductClick, getMouseClick } from "../utils"
import { useRuntimeConfig } from "../../../hooks/runtimeConfig"
import RawHTML from "../ExpertProfile/RawHTML"
import classes from "./ExpertEntry.module.scss"

interface IExpertEntryProps {
  expert: IAdvisor
  linkClassName?: string
  /**
   * listing identifier for tracking purposes
   */
  listingName?: string
}

const avatarImageSizes = {
  mobile: {
    width: 80,
    height: 100,
  },
  desktop: {
    width: 160,
    height: 200,
  },
}

const ExpertEntry: React.FC<IExpertEntryProps> = memo(
  ({ expert, linkClassName, listingName }) => {
    const {
      listing_number: listingNumber,
      avatar,
      name,
      products,
      description,
      status,
      promotion,
    } = expert

    const { getRuntimeConfigByKey } = useRuntimeConfig()
    const link = buildProfileUrl(name, listingNumber)
    const { trackEvent, setProperties } = useTracking()

    const clickHandler = useCallback(
      (e: React.MouseEvent) => {
        trackProductClick(
          getMouseClick(e),
          expert,
          listingName,
          trackEvent,
          getRuntimeConfigByKey("MANDATOR_NUMBER")
        )

        setProperties({
          listingName,
        })
      },
      [expert, getRuntimeConfigByKey, listingName, setProperties, trackEvent]
    )

    const computedLinkClassName = clsx(classes.details, linkClassName)

    return (
      <Box className={classes.wrapper} data-testid="expert-entry">
        <AdviqoLink
          underline="none"
          className={computedLinkClassName}
          onClick={clickHandler}
          onContextMenu={clickHandler}
          to={link}
        >
          <Box className={classes.avatarWrapper}>
            <Avatar
              photo={avatar}
              displayName={name}
              onlineStatus={status}
              imgSizes={avatarImageSizes}
            />
          </Box>
          <Box className={classes.detailsCol}>
            <Box className={classes.caption}>
              <Typography
                className={classes.expertName}
                data-testid="expertName"
              >
                {name}
              </Typography>
              <ExpertOnlineStatusLabel onlineStatus={status} />
            </Box>
            <div className={classes.descriptionWrapper}>
              <ExpertShortStats
                labels={expert.labels}
                rating={expert.rating}
                readingsCount={expert.readings_count}
                sign={expert.sign}
                languages={expert.languages}
              />
              <RawHTML
                text={description}
                rootClass={classes.titleDescription}
              />
              <Typography
                className={classes.profileLink}
                data-testid="profileLink"
              >
                Zum Profil <ArrowIcon height={14} width={18} />
              </Typography>
            </div>
          </Box>
        </AdviqoLink>
        <Products
          promotion={promotion}
          offeredProducts={products}
          listingNo={listingNumber}
          photo={avatar}
          displayName={name}
          rootClassName={classes.productsRoot}
          initSrc={listingName}
          advisorStatus={status}
          productsListClassName={classes.productsList}
        />
      </Box>
    )
  }
)

ExpertEntry.displayName = "ExpertEntry"

export default ExpertEntry

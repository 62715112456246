export function getIntervalTimeForPolling(
  shouldFetch: boolean,
  interval: number,
  loading: boolean
): number | null {
  if (!shouldFetch) return null
  if (!interval) return null
  if (loading) return null

  return interval
}

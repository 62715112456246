import React from "react"
import clsx from "clsx"
import { Box } from "../../_common"
import classes from "./RawHTML.module.scss"

// replacing escaped symbols
const replaceAllNewLines = (t: string) => t.replace(/\\r\\n/g, "<br />")

interface IRawHTMLProps {
  text: string

  rootClass?: string
}

const RawHTML: React.FC<IRawHTMLProps> = ({ text, rootClass }) => {
  const resultClasses = clsx(rootClass, classes.base)
  return (
    <Box
      className={resultClasses}
      dangerouslySetInnerHTML={{
        __html: replaceAllNewLines(text),
      }}
    />
  )
}

export default RawHTML

import { useCallback, useEffect } from "react"
import useSWR, { BareFetcher } from "swr"
import { IAdvisorProfile } from "../../../../types"
import { fetchWithConfig } from "../../../../utils/rest"
import { useRuntimeConfig } from "../../../../hooks/runtimeConfig"
import { SWR_KEYS } from "../../../../utils/rest/constants"
import useExpertOnlineStatus from "./useExpertOnlineStatus"

interface IFetchExpertProfilePayload {
  listingNo?: string
  advisorId?: number
}

const useFetchExpertProfileAPI = (
  payload: IFetchExpertProfilePayload,
  withPolllingStatus = false
): {
  expert: IAdvisorProfile | undefined
  loading: boolean
  error: boolean
  purgeCache: () => void
} => {
  const { getRuntimeConfig } = useRuntimeConfig()
  const { listingNo, advisorId } = payload
  const key = advisorId || listingNo

  const { data, error, isLoading, mutate } = useSWR<IAdvisorProfile>(
    key ? { url: SWR_KEYS.ADVISOR_PROFILE, key } : null,
    (({ url, headers }: { url: string; headers?: Record<string, string> }) => {
      return fetchWithConfig(
        {
          url,
          data: {
            // eslint-disable-next-line camelcase
            ...(listingNo && { listing_number: listingNo }),
            // eslint-disable-next-line camelcase
            ...(advisorId && { advisor_id: advisorId }),
          },
          headers,
          method: "POST",
        },
        getRuntimeConfig,
        "advice"
      )
    }) as BareFetcher<IAdvisorProfile>
  )
  const pollStatus = useExpertOnlineStatus(
    data?.advisor.id ? [data.advisor.id] : [],
    withPolllingStatus,
    {
      min: 0,
      max: 1,
    }
  )

  const status =
    pollStatus && data ? pollStatus[data.advisor.id]?.status : undefined

  const revalidateOnStatusUpdate = useCallback(() => mutate(), [mutate])

  /**
   * @note We have 2 options to get updated status of expert:
   * - revalidateOnFocus: true (default setting);
   * - revalidateOnStatusUpdate (when withPolllingStatus: true);
   *
   * When polling is happening we listen for status updates
   * and revalidate data when status changes.
   */
  useEffect(() => {
    revalidateOnStatusUpdate()
  }, [status, revalidateOnStatusUpdate])

  const purgeCache = useCallback(() => mutate(undefined), [mutate])

  return {
    expert: data,
    loading: isLoading,
    error: !!error,
    purgeCache,
  }
}

export default useFetchExpertProfileAPI
